<template>
  <MainLayout>
    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90vh"
    >
      <div v-if="item">
        <merchant-card v-if="userIsSuperAdmin && item.isMerchant" v-bind="{item}"/>
        <h1>
          <i :class="['bx', resource.icon]"></i>
          {{ item.$meta.title }}
          <span v-if="!item.active" class="text-danger small">{{$t('common.inactive')}}</span>
        </h1>

        <action-bar v-bind="{item,resource}" @action="action" />

        <div class="row row-cols-1 row-cols-md-2 mt-4" :class="{'inactive': !item.active}">
          <div class="col mb-4">
            <b-card>
              <template #header> 
                <i class='bx bxs-user' />
                {{$t('users.label')}}
              </template>
              <dl>
                <value label="common.name" icon="bx-purchase-tag" :value="item.name" />
                <value label="common.email" icon="bx-envelope" :value="item.email" />

                <value v-if="item.country" label="common.country" icon="bx-globe" :value="item.country">
                  <country :country="item.country" />
                </value>

                <value label="users.role" icon="bxs-user-detail" :value="true">
                  <template v-for="(role,index) in resource.roles">
                    <div :key="index" v-if="role.code & item.role">{{$t(role.label)}}</div>
                  </template>
                </value>

              </dl>
            </b-card>

            <b-card v-if="userIsSuperAdmin && item.isStore && !item.isSuperAdmin">
              <template #header>
                <i class="bx bxs-store"></i>
                {{ $t("users.pudo-warehouse") }}
              </template>

              <dl>
                <value label="users.locations" icon="bx bxs-map" :value="item.$locations">
                    <div v-for="location in item.$locations" :key="location.id">
                      <router-link :to="{name: `locations-show`,params:{id: location.id}}">
                        {{location.name}}
                      </router-link>
                    </div>
                </value>
              </dl>
            </b-card>
          </div>
          <div class="col mb-4">
            <b-card v-if="isOwn || userIsSuperAdmin">
              <template #header>
                <i class="bx bx-cog"></i>
                {{ $t("users.preferences.label") }}
              </template>
              <dl>
                <value label="users.preferences.locale" icon="bx-message-square-detail" :value="getLocaleName(item.locale)" />
                <value v-if="item.isMerchant" label="users.preferences.default-pickup-location" icon="bx-map" :value="item.$meta.default_pickup_name" />
                <value label="users.preferences.default-export-format" icon="bxs-file-export" :value="defaultExportFormat" />
                <value v-if="userIsSuperAdmin && !item.isMerchant" label="merchants.features" icon="bx-hive" :value="item.preferences.features || null">
                  <template v-for="(feature,index) in features">
                    <div :key="index" v-if="feature.code & item.preferences.features">{{$t(feature.label)}}</div>
                  </template>
                </value>
              </dl>
            </b-card>

            <packages-card v-if="userIsSuperAdmin && (item.isDriver || item.$packages.length)" :packages="item.$packages" />

          </div>
        </div>
      </div>
      <debug v-bind="{item}" />
    </b-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemShow from '@/components/ItemShow'
import MerchantCard from '@/components/Cards/Merchant'
import Value from "@/components/Value";
import ActionBar from '@/components/ActionBar.vue'
import { mapGetters } from 'vuex';
import { availableLocales } from '@/i18n';
import { exportFormats } from '@/resources/Shipment'
import Merchant from '@/resources/Merchant';
import PackagesCard from '@/components/Cards/Packages'
import Country from "@/components/Country";

export default {
  extends: ItemShow,
  components: { 
    MainLayout,
    Value,
    ActionBar,
    MerchantCard,
    PackagesCard,
    Country,
  },
  data() {
    return {
      isOwn: false,
      features: Merchant.features
    }
  },
  created() {
    if(this.user.id == this.$route.params.id) {
      this.isOwn = true
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    defaultExportFormat() {
      return exportFormats.find(f => f.code === this.item.preferences.default_export_format)?.label
    }
  },
  methods: {
    getLocaleName(locale) {
      return this.$t(availableLocales.find(l => l.code == locale).label)
    }
  }
};
</script>